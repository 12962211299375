import React from "react";

class Footer extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <footer>
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-sm-10 col-xs-12 footer-copyright footer-align-copyright" style={{ fontSize: "16px" }}>
                &copy; {new Date().getFullYear()} <b>Microtraining</b>
              </div>
              <div className="col-sm-2 col-xs-12 footer-copyright footer-align-widget" />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
