import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

export const trackEvent = (category, action, label, value) => {
  var gaObject = {};
  var fbObject = {};

  if (category) {
    gaObject.category = category;
  }
  if (action) {
    gaObject.action = action;
  }
  if (label) {
    gaObject.label = label;
    fbObject.label = label;
  }
  if (value) {
    gaObject.value = value;
    fbObject.value = value;
  }

  ReactGA.event(gaObject);

  ReactPixel.trackCustom(action, fbObject);
};
