import React from "react";
import { connect } from "react-redux";
import PageViewEvent from "../../Dashboard/PageViewEvent";
import { trackEvent } from "utilities/trackEvent";

import Footer from "./Footer";

import IntroView from "./Index/IntroView";
import NavbarInstanceView from "./NavbarInstanceView";

class Index extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      is_movielish: true,
    };
    this.showSurvey = this.showSurvey.bind(this);
    this.closeSurvey = this.closeSurvey.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.landingReducer !== nextProps.landingReducer || this.state.showModal !== nextState.showModal;
  }

  showSurvey() {
    this.setState({
      showModal: true,
    });

    trackEvent("Landing", "start-now", null, null);
  }

  closeSurvey() {
    this.setState({
      showModal: false,
    });
  }

  render() {
    return (
      <article>
        <NavbarInstanceView />
        <main>
          <IntroView />
        </main>
        <Footer />
        <PageViewEvent />
      </article>
    );
  }
}

const IndexView = connect(state => {
  return state;
})(Index);

export default IndexView;
