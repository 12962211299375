import React from "react";
import { Link } from "react-router-dom";
import { Navbar, NavItem, Nav, Container } from "react-bootstrap";

class NavbarInstanceView extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      navbar_no_bg: true,
    };
    this.handleOnScroll = this.handleOnScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state !== nextState || this.props !== nextProps;
  }

  handleOnScroll() {
    var scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    if (scrollTop >= 100) {
      this.setState({
        navbar_no_bg: false,
      });
    } else {
      this.setState({
        navbar_no_bg: true,
      });
    }
  }
  render() {
    return (
      <header>
        <Navbar
          collapseOnSelect
          className={"navbar navbar-fixed-top " + (this.state.navbar_no_bg ? " navbar-no-bg" : " navbar-bg")}
        >
          <Container className="d-flex justify-content-between">
            <Navbar.Brand>
              <Link className="navbar-brand" to="/">
                <b>Microtraining</b>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
              <Nav />
              <Nav>
                <NavItem eventKey={1}>
                  <Link to="/">HOME</Link>
                </NavItem>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

export default NavbarInstanceView;
