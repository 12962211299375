import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import React from "react";

class PageViewEvent extends React.Component {
  componentDidMount() {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    ReactPixel.trackCustom("page-view", { path: window.location.pathname + window.location.search });
  }

  render() {
    return null;
  }
}

export default PageViewEvent;
