import React from "react";
import { MSG_LOGIN_GOOGLE } from "../../messages";

const IntroView = () => {
  const organizationName = window.location.hostname.split(".")[0];
  const handleGoogleLoginClick = event => {
    if (window.isNative) {
      event.preventDefault();
      window.postMessage(MSG_LOGIN_GOOGLE);
    }
  };
  const err = window.location.search.split("=")[1];

  return (
    <div>
      <div>
        <div className="view-intro">
          <div className="container p-t-50" />
        </div>
        <div>
          {err && err === "user_does_not_exist" && (
            <div class="alert alert-danger text-center" role="alert">
              User does not belong to this organization.
            </div>
          )}
          <div className="container p-t-40">
            <div className="row text-center">
              <div className="col-sm-3 col-xs-0" />
              <div className="col-sm-6 col-xs-12">
                <div>
                  <h3>
                    <b>Training portal of {organizationName}</b>
                  </h3>
                  <hr className="hr-login" />
                  <p>
                    <a
                      id="btn-login-google"
                      className="btn loginBtn loginBtn--google"
                      href="/auth/google_oauth2"
                      onClick={handleGoogleLoginClick}
                    >
                      Sign in with Gmail
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-sm-3 col-xs-0" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroView;
