import React from "react";
import Cookies from "universal-cookie";

class NotFoundView extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const cookies = new Cookies();
    let lng = cookies.get("lng");
    if (typeof lng === "undefined") {
      cookies.set("lng", "en");
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12 text-center m-t-100">
            <p style={{ fontSize: "100px" }}>404</p>
            <br />
            <p className="m-t-10" style={{ fontSize: "35px" }}>
              {lng === "vi" ? "Không tìm thấy trang" : "Page not found"}
            </p>
            <br />
            <p style={{ fontSize: "22px" }}>
              {lng === "vi" ? "Về " : "Goto"}
              <a href="/"> {lng === "vi" ? "Trang chủ" : "Homepage"}</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundView;
